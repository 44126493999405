import axios from "axios";
import _ from "lodash";
import BigNumber from "bignumber.js";
import {
  ComputeBudgetInstruction,
  ComputeBudgetProgram, LAMPORTS_PER_SOL,
  PublicKey,
  Transaction,
  TransactionInstruction, TransactionMessage, VersionedTransaction
} from "@solana/web3.js";
import * as web3Service from "./web3Service";
import * as jitoBundleService from "@/services/jitoBundleService";
import {createCloseAccountInstruction, getAssociatedTokenAddressSync} from "@solana/spl-token";

const axiosInstance = axios.create({
  timeout: 10000,
});

export async function getQuote(params: any) {
  return (await axiosInstance.get("https://api.jup.ag/swap/v1/quote", { params })).data;
}

export async function getSwap(swapParams: any) {
  const swapRes = (await axiosInstance.post(`https://api.jup.ag/swap/v1/swap`, swapParams)).data;
  const swapTx = VersionedTransaction.deserialize(Buffer.from(swapRes.swapTransaction, "base64"));

  if (swapParams.prioritizationFeeLamports?.jitoTipLamports) {
    // console.log("find index from staticAccountKeys", swapTx.message.staticAccountKeys.findIndex(id => id.equals(ComputeBudgetProgram.programId)));
    swapTx.message.compiledInstructions.unshift({
      programIdIndex: swapTx.message.staticAccountKeys.findIndex(id => id.equals(ComputeBudgetProgram.programId)),
      accountKeyIndexes: [],
      data: ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 0 }).data
    });
    /*for (const compiledInstruction of swapTx.message.compiledInstructions) {
      const programId = swapTx.message.staticAccountKeys[compiledInstruction.programIdIndex];
      const keys = compiledInstruction.accountKeyIndexes.map(index => ({
        pubkey: swapTx.message.staticAccountKeys[index],
        isSigner: swapTx.message.isAccountSigner(index),
        isWritable: swapTx.message.isAccountWritable(index),
      }));
      const data = Buffer.from(compiledInstruction.data);
      const instruction = new TransactionInstruction({ programId, keys, data });

      if (ComputeBudgetProgram.programId.equals(programId)) {
        console.log("programIdIndex", compiledInstruction.programIdIndex);
        console.log("accountKeyIndexes", compiledInstruction.accountKeyIndexes);
      }
    }*/
  }
  swapRes.swapTransaction = swapTx;

  return swapRes;
}

function deserializeInstruction(instruction) {
  return new TransactionInstruction({
    programId: new PublicKey(instruction.programId),
    keys: instruction.accounts.map((key) => ({
      pubkey: new PublicKey(key.pubkey),
      isSigner: key.isSigner,
      isWritable: key.isWritable,
    })),
    data: Buffer.from(instruction.data, "base64"),
  });
}

export async function getSwapTransaction(swapParams, tipAmountSOL?: BigNumber.Value, closeInputTokenAccount?: boolean) {
  const fetchLatestBlockhash = web3Service.solanaWeb3.getLatestBlockhash();
  const {
    tokenLedgerInstruction, // If you are using `useTokenLedger = true`.
    computeBudgetInstructions, // The necessary instructions to setup the compute budget.
    setupInstructions, // Setup missing ATA for the users.
    swapInstruction, // The actual swap instruction.
    cleanupInstruction, // Unwrap the SOL if `wrapAndUnwrapSol = true`.
    addressLookupTableAddresses, // The lookup table addresses that you can use if you are using versioned transaction.
    dynamicSlippageReport,
  } = (await axiosInstance.post(`https://api.jup.ag/swap/v1/swap-instructions`, swapParams)).data;

  if (swapParams.dynamicSlippage && dynamicSlippageReport) {
    console.log(
      "SwapSolana dynamic slippage, requested", swapParams.dynamicSlippage.maxBps,
      "quoted", swapParams.quoteResponse.slippageBps,
      "actual", dynamicSlippageReport.slippageBps
    );
  }

  const instructions: TransactionInstruction[] = [];
  if (computeBudgetInstructions?.length) {
    const ixs = computeBudgetInstructions.map(deserializeInstruction);
    instructions.push(...ixs);
    // without SetComputeUnitPrice ix, wallets set their own fee. so here we have to explicitly set it to zero
    if (!ixs.some(i => ComputeBudgetInstruction.decodeInstructionType(i) === "SetComputeUnitPrice")) {
      instructions.push(ComputeBudgetProgram.setComputeUnitPrice({
        microLamports: 0
      }));
    }
  }
  if (setupInstructions?.length) {
    instructions.push(...setupInstructions.map(deserializeInstruction));
  }
  instructions.push(deserializeInstruction(swapInstruction));
  if (cleanupInstruction) {
    instructions.push(deserializeInstruction(cleanupInstruction));
  }

  const addressLookupTableAccounts = await web3Service.getAddressLookupTableAccounts(
    addressLookupTableAddresses.map(address => new PublicKey(address))
  );

  const walletPk = new PublicKey(swapParams.userPublicKey);

  if (closeInputTokenAccount) {
    const tokenAccountPk = getAssociatedTokenAddressSync(new PublicKey(swapParams.quoteResponse.inputMint), walletPk);
    instructions.push(createCloseAccountInstruction(tokenAccountPk, walletPk, walletPk));
  }

  const tipAmountBN = BigNumber(tipAmountSOL);
  if (tipAmountBN.gt(0)) {
    instructions.push(jitoBundleService.tipInstruction(
      walletPk,
      tipAmountBN.multipliedBy(LAMPORTS_PER_SOL).toNumber()
    ));
  }

  const blockHashWithExpiry = await fetchLatestBlockhash;
  const transaction = new VersionedTransaction(new TransactionMessage({
    payerKey: walletPk,
    recentBlockhash: blockHashWithExpiry.blockhash,
    instructions,
  }).compileToV0Message(addressLookupTableAccounts));

  return { dynamicSlippageReport, transaction, blockHashWithExpiry };
}


export async function getPrices(mints: string[]) {
  const fetchPricesRet = await axios.get("https://api.jup.ag/price/v2", {
    params: {
      ids: _.uniq(mints).join(",")
    }
  });
  const ret: Record<string, BigNumber> = {};
  for (const mint in fetchPricesRet.data.data) {
    ret[mint] = BigNumber(fetchPricesRet.data.data[mint].price);
  }
  return ret;
}


export async function getJitoTipFloors() {
  return (await axios.get("https://worker.jup.ag/jito-floor")).data;
}
