import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=2d852a2c&scoped=true"
import script from "./Header.vue?vue&type=script&setup=true&lang=ts"
export * from "./Header.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d852a2c",
  null
  
)

export default component.exports