<template>
  <nav class="sticky-top bg-white border-bottom">
    <b-container class="d-flex flex-row align-items-center justify-content-between flex-wrap py-1">
      <div class="flex-shrink-0">
        <b-button variant="link" to="/swap">Swap</b-button>
        <template v-if="walletAddress">
          <b-button v-if="walletAddress" variant="link" to="/cex-wallet">CEX wallet</b-button>
          <b-button v-if="walletAddress" variant="link" to="/arbitrage">Arbitrage</b-button>
          <b-button v-if="walletAddress" variant="link" to="/cex-hedging">CEX Hedging</b-button>
          <b-button id="header-more-btn" variant="link" class="text-decoration-none">
            More<small><b-icon-caret-down/></small>
          </b-button>
          <b-popover target="header-more-btn" triggers="hover" placement="bottom">
            <b-button class="d-block" variant="link" to="/cex-balance-watch-orders">Balance watch orders</b-button>
            <b-button class="d-block" variant="link" to="/delist-trade-orders">Delist trade orders</b-button>
            <b-button class="d-block" variant="link" to="/feed">Feed</b-button>
            <b-button class="d-block" variant="link" to="/cex-accounts">Manage CEX accounts</b-button>
            <b-button class="d-block" variant="link" to="/private-key-wallets">Private key wallets</b-button>
          </b-popover>
        </template>
      </div>
      <div>
        <b-button v-if="!walletAddress" variant="link" @click="onClickConnectWallet">Connect web3 wallet</b-button>
        <div class="text-right" v-else>
          <div>
            <img :src="connectedWallet.icon" :alt="connectedWallet.label" v-b-tooltip :title="connectedWallet.label"
                 class="mr-1" style="width: 20px; height: 20px;" />
            <img :src="networkLogoUrl" :alt="networkName" v-b-tooltip :title="networkName"
                 class="mr-1" style="width: 20px; height: 20px;" />
            <small class="text-monospace">{{ walletAddress }}</small>
          </div>
          <div>
            <small>
              <span class="text-secondary cursor-pointer" v-if="displaySessionTimeLeft"
                    @click="onClickRenewSession"
                    v-b-tooltip:hover title="Renew session">
                <b-icon-clock /> {{ displaySessionTimeLeft }} ·
              </span>
              <a class="text-secondary" :href="explorerUrl" target="_blank">Explorer</a> ·
              <a class="text-secondary" :href="debankUrl" target="_blank">DeBank</a> ·
              <span class="text-secondary cursor-pointer" @click="disconnectConnectedWallet">Disconnect</span>
            </small>
          </div>
        </div>
      </div>
    </b-container>
  </nav>
</template>

<style lang="scss" scoped>

</style>

<script setup lang="ts">
  import { ref, reactive, readonly, onMounted, onDeactivated, computed, watch } from "vue";
  import { useOnboard } from "@web3-onboard/vue";
  import { walletAddress, chainId, browserProvider } from "@/services/onboard";
  import _ from "lodash";
  import BigNumber from "bignumber.js";
  import {ethers} from "ethers";
  import * as authService from "@/services/authService";
  import * as utils from "@/utils";
  import * as constants from "@/constants";
  import {DateTime} from "luxon";

  const { connectWallet, connectedWallet, disconnectConnectedWallet } = useOnboard();

  const displaySessionTimeLeft = ref("");
  let refreshAccountTimeoutHandler = null;
  let isDestroyed = false;

  const explorerUrl = computed(() => walletAddress.value && utils.getExplorerUrl(chainId.value, "address", walletAddress.value));
  const debankUrl = computed(() => walletAddress.value && `https://debank.com/profile/${walletAddress.value}`);

  const networkLogoUrl = computed(() => constants.NETWORK_LOGO[chainId.value]);
  const networkName = computed(() => constants.chainNames[chainId.value]);

  onMounted(() => {
    const stopWatch = watch(browserProvider, () => {
      stopWatch();
      refreshAccountState();
    });
  });

  async function refreshAccountState() {
    clearTimeout(refreshAccountTimeoutHandler);
    if (isDestroyed) return;

    try {
      if (walletAddress.value) {
        const auth = JSON.parse(localStorage.getItem("auth-" + walletAddress.value));
        if (auth) {
          const tsDiffToNow = DateTime.fromMillis(auth.ts ?? 0).plus({ days: 6 }).diffNow();
          if (tsDiffToNow.as("milliseconds") > 0) {
            displaySessionTimeLeft.value = tsDiffToNow.toFormat("hh'h' mm'm'");
          } else {
            displaySessionTimeLeft.value = "Session expired";
          }
        } else {
          displaySessionTimeLeft.value = "";
        }
      } else {

      }

    } catch (e) {
      console.error(e);
    }

    clearTimeout(refreshAccountTimeoutHandler);
    refreshAccountTimeoutHandler = setTimeout(() => refreshAccountState(), 5000);
  }

  async function onClickConnectWallet() {
    await connectWallet();
    await refreshAccountState();
  }

  function onClickRenewSession() {
    authService.refreshAuth();
  }

  onDeactivated(() => {
    clearTimeout(refreshAccountTimeoutHandler);
    isDestroyed = true;
  });

</script>
