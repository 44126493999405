import Vue from 'vue';
import App from './App.vue';
import router from './router';
import 'vue-class-component/hooks';
import _ from "lodash";

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import "@/app.scss";

import { Buffer } from "buffer";
(globalThis as any).Buffer = Buffer;

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

import vSelect from 'vue-select';
Vue.component('v-select', vSelect);

import "@/services/onboard";

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');

addEventListener("click", event => {
  console.log("event listener once click");
  window["canPlayAudio"] = true;
}, { once: true });

Vue.config.productionTip = false;
